import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import icon from './assets/logo-zero.png'
export class header extends Component {
  render() {
    return (
      <div className='header'>
      <div className='main' style={{display:'flex', width:'83%', margin:'0 auto', alignItems:'center', gap:'36%'}}>
        <div className='w-icon'>
         <Link to="/">
            <img className='logo' src={icon} />
         </Link>
        </div>
        <div className='page-links'>
            <Link to="/">Beranda</Link>
            <Link to="/wordpress-page">Wordpress</Link>
            <Link to="/facebook-page">Facebook</Link>
        </div>
      </div>
      </div>
    )
  }
}

export default header