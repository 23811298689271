import React, { Component } from 'react'

export class footer extends Component {
  render() {
    return (
      <div className='footer'>
        <p className='footer-line'>Copyright © Jupri Maulana</p>
      </div>
    )
  }
}

export default footer