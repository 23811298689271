import React, { useState, useEffect } from 'react'; // Add useState and useEffect
import { useParams } from 'react-router-dom'; // Add useParams from react-router-dom
import data from '../beranda.json';
import { Link } from 'react-router-dom';
import '../App.css'
import icon1 from './assets/icons8-facebook.svg'
import icon2 from './assets/icons8-whatsapp.svg'
import icon3 from './assets/icons8-pinterest.svg'
import icon4 from './assets/icons8-twitter.svg'

function Pformate() {
  const { id } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const currentPageData = data.find(page => page.id === parseInt(id));
    setPageData(currentPageData);
  }, [id]);

  if (!pageData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Pformate">
      <div className='main' style={{width:'70%', margin:'0 auto'}} key={pageData.id}>
        <Link to={pageData.hUrl} style={{textDecoration:'none', color:'#1e73be'}}>Home</Link>
        <h1 style={{ fontSize:'25px', lineHeight:'30px', fontWeight:'600', fontFamily:'inherit', letterSpacing:'0.5px'}} className='mtb-10 p-0'>{pageData.heading}</h1>
        <div className='mb-20px' style={{display:'flex', gap:'20px'}}>
          <p style={{ margin:'0', padding:'0', color:'#999999', fontSize:'13xp'}}>{pageData.user}</p>
          <p style={{ margin:'0', padding:'0', color:'#999999', fontSize:'13xp'}}>{pageData.date}</p>
        </div>
        <img style={{borderRadius:'5px', width:'100%'}} src={process.env.PUBLIC_URL + pageData.image}/>
        <div className='media-links mt-20px' style={{display:'flex', gap:'20px', justifyContent:'center'}}>
          <a href='#'>
            <img className='facebook' src={icon1} />
          </a> 
          <a href='#'>
            <img className='whatsapp' src={icon2} />
          </a> 
          <a href='#'>
            <img className='pinterest' src={icon3} />
          </a> 
          <a href='#' className='t-link'>
            <img className='twitter' src={icon4} />
          </a>
        </div>

          <div >
            <p className='paragraph'>{pageData.paragraph1}</p>
            <p className='paragraph'>{pageData.paragraph2}</p>
            <p className='paragraph'>{pageData.paragraph5}</p>
            <img className='pc-image' src={pageData.image2} style={{width:'50%'}} />
            <p className='paragraph'>{pageData.paragraph6}</p>
            <p className='paragraph'>{pageData.paragraph7}</p>

            <h1 className='heading'>{pageData.heading1}</h1>
            <p className='paragraph'>{pageData.paragraph3}</p>

            <h1 className='heading'>{pageData.heading2}</h1>
            <p className='paragraph'>{pageData.paragraph4}</p>

            <h1 className='heading'>{pageData.heading3}</h1>
            <p className='paragraph'>{pageData.paragraph8}</p>

            <h1 className='heading'>{pageData.heading4}</h1>
            <p className='paragraph'>{pageData.paragraph9}</p>

            <h1 className='heading'>{pageData.heading5}</h1>
            <p className='paragraph'>{pageData.paragraph10}</p>

            <h1 className='heading'>{pageData.heading6}</h1>
            <p className='paragraph'>{pageData.paragraph11}</p>

            <h1 className='heading'>{pageData.heading7}</h1>
            <p className='paragraph'>{pageData.paragraph12}</p>

            <h1 className='heading'>{pageData.heading8}</h1>
            <p className='paragraph'>{pageData.paragraph13}</p>

            <h1 className='heading'>{pageData.heading9}</h1>
            <p className='paragraph'>{pageData.paragraph14}</p>

            <h1 className='heading'>{pageData.heading10}</h1>
            <p className='paragraph'>{pageData.paragraph15}</p>

            <h1 className='heading'>{pageData.heading11}</h1>
            <p className='paragraph'>{pageData.paragraph16}</p>

            <h1 className='heading'>{pageData.heading12}</h1>
            <p className='paragraph'>{pageData.paragraph17}</p>

            <h1 className='heading'>{pageData.heading13}</h1>
            <p className='paragraph'>{pageData.paragraph18}</p>

            <h1 className='heading'>{pageData.heading14}</h1>
            <p className='paragraph'>{pageData.paragraph19}</p>

            <h1 className='heading'>{pageData.heading15}</h1>
            <p className='paragraph'>{pageData.paragraph20}</p>

            <h1 className='heading'>{pageData.heading16}</h1>
            <p className='paragraph'>{pageData.paragraph21}</p>

            <h1 className='heading'>{pageData.heading17}</h1>
            <p className='paragraph'>{pageData.paragraph22}</p>

            <h1 className='heading'>{pageData.heading18}</h1>
            <p className='paragraph'>{pageData.paragraph23}</p>

            <h1 className='heading'>{pageData.heading19}</h1>
            <p className='paragraph'>{pageData.paragraph24}</p>

            <h1 className='heading'>{pageData.heading20}</h1>
            <p className='paragraph'>{pageData.paragraph25}</p>

            <h1 className='heading'>{pageData.heading21}</h1>
            <p className='paragraph'>{pageData.paragraph26}</p>

            <h1 className='heading'>{pageData.heading22}</h1>
            <p className='paragraph'>{pageData.paragraph27}</p>

            <h1 className='heading'>{pageData.heading23}</h1>
            <p className='paragraph'>{pageData.paragraph28}</p>

            <h1 className='heading'>{pageData.heading24}</h1>
            <p className='paragraph'>{pageData.paragraph29}</p>
            <p className='paragraph'>{pageData.paragraph30}</p>

            <img src={pageData.image1} />
          </div>
        </div>
    </div>
  );
}

export default Pformate;
