import React from 'react';
import '../App.css'
import data from '../beranda.json'; // JSON file ko import kiya
import { Link } from 'react-router-dom';
const beranda = () => {
  return (
    <div className="maingrid">
      {data.map((item) => (
        <Link to={item.url} className="griditem" key={item.id} style={{ border:'1px solid #e8e8e8', borderRadius:'5px' }}>
          <img height={200} src={process.env.PUBLIC_URL + item.image} style={{ borderRadius:'5px' }} />
          <div style={{ padding:'10px', width:'80%', margin:'0 auto', marginTop:'-15px', background:'#ffffff', position:'relative', borderRadius:'5px' }}>
            <p className='f-f-d'>{item.name}</p>
            <h1 className='f-h-d'>{item.heading}</h1>
            <p className='f-p-d'>{item.paragraph}</p>
            <div className='u-d' style={{display:'flex', gap:'15px'}}>
              <p>{item.user}</p>
              <p>{item.date}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default beranda;
