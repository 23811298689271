import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Home from './components/beranda';
import Facebook from './components/facebook';
import Wordpress from './components/wordpress';
import Header from './components/header';
import Footer from './components/footer';
import ReactDOM from "react-dom/client";
import Pageformate from './components/Pformate';

function Layout() {
  return (
    <>
      <Header />
      <Outlet />  {/* This will render the nested route components */}
      <Footer />
    </>
  );
}

const App = () => {
  return (
    <div className='app'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='wordpress-page' element={<Wordpress />} />
            <Route path='facebook-page' element={<Facebook />} />
            <Route path="/page/:id" element={<Pageformate />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

export default App;
